// ------------------------------------------------------
// NOTE: インターフェースの定義
/**
 * @description 周波数のインターフェス
 * @property {FrequencyBandType} frequencyBand 周波数バンド
 * @property {number} frequency 周波数
 * @property {number} channel チャンネル
 * @property {string} note メモ
 * @interface
 */
export interface FrequencyType {
  frequencyBand: FrequencyBandType;
  frequency: number;
  channel?: number;
  note?: string;
  color?: string;
}

/**
 * @description 周波数リストのインターフェース
 * @property {string} key 周波数バンド
 * @property {number} first 先頭周波数
 * @property {number} last 終端周波数
 * @property {FrequencyType[]} list 対象の周波数バンドに含まれる周波数情報
 * @interface
 */
export interface FrequenciesType {
  [key: string]: { first: number; last: number; list: FrequencyType[] };
}

/**
 * @description 周波数チャネルグループのインターフェース
 * @property {number} frequency 周波数
 * @property {number} channel チャンネル
 * @property {number} min 参照する周波数の最小
 * @property {number} max 参照する周波数の最大
 * @interface
 */
export interface FrequencyChannelType {
  frequency: number;
  channel: number;
  min?: number;
  max?: number;
  color?: string;
}

/**
 * @description 周波数ノートのインターフェース
 * @property {FrequencyBandType} frequencyBand 周波数バンド
 * @property {number} frequency 周波数
 * @property {number} channel チャンネル
 * @property {string} note メモ
 * @interface
 */
export interface FreqNoteType {
  [no: string]: {
    label: string;
    text: string;
  };
}

/**
 * @description 周波数バンドの種別
 * @type
 */
export type FrequencyBandType = '' | 'ism900' | 'w2_4g' | 'w5g' | 'l5g';

// ------------------------------------------------------
// NOTE: 定数の定義
/**
 * @description 周波数バンドの定数
 * @constant
 */
export const FREQUENCY_BAND = {
  FREQUENCY_920M: { ID: 'ism900', LABEL: '920MHz' },
  FREQUENCY_WLAN2_4G: { ID: 'w2_4g', LABEL: '無線LAN2.4GHz' },
  FREQUENCY_WLAN5G: { ID: 'w5g', LABEL: '無線LAN5GHz' },
  FREQUENCY_L5G: { ID: 'l5g', LABEL: 'L5G' },
} as const;

/**
 * @description
 *  周波数の単位の定数 \
 *  ・frequency < 1000   なら  FREQ_MHZ \
 *  ・frequency >= 1000  なら  FREQ_GHZ
 * @constant
 */
export const FREQUENCY_UNIT = {
  FREQ_MHZ: '[MHz]',
  FREQ_GHZ: '[GHz]',
} as const;

/**
 * @description 周波数リストの定数
 * @constant
 */
export const FREQUENCIES: FrequenciesType = {
  [FREQUENCY_BAND.FREQUENCY_920M.ID]: {
    first: 920.6,
    last: 928.0,
    list: [
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 920.6, channel: 24 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 920.8, channel: 25 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 921.0, channel: 26 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 921.2, channel: 27 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 921.4, channel: 28 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 921.6, channel: 29 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 921.8, channel: 30 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 922.0, channel: 31 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 922.2, channel: 32 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 922.4, channel: 33 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 922.6, channel: 34 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 922.8, channel: 35 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 923.0, channel: 36 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 923.2, channel: 37 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 923.4, channel: 38 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 923.6, channel: 39 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 923.8, channel: 40 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 924.0, channel: 41 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 924.2, channel: 42 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 924.4, channel: 43 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 924.6, channel: 44 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 924.8, channel: 45 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 925.0, channel: 46 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 925.2, channel: 47 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 925.4, channel: 48 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 925.6, channel: 49 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 925.8, channel: 50 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 926.0, channel: 51 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 926.2, channel: 52 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 926.4, channel: 53 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 926.6, channel: 54 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 926.8, channel: 55 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 927.0, channel: 56 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 927.2, channel: 57 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 927.4, channel: 58 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 927.6, channel: 59 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 927.8, channel: 60 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_920M.ID, frequency: 928.0, channel: 61 },
    ],
  },
  [FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID]: {
    first: 2412,
    last: 2494,
    list: [
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2412, channel: 1 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2417, channel: 2 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2422, channel: 3 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2427, channel: 4 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2432, channel: 5 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2437, channel: 6 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2442, channel: 7 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2447, channel: 8 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2452, channel: 9 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2457, channel: 10 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2462, channel: 11 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2467, channel: 12 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2472, channel: 13 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2474, channel: 14 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2476, channel: 14 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2478, channel: 14 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2480, channel: 14 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2482, channel: 14 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2484, channel: 14 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2486, channel: 14 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2488, channel: 14 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2490, channel: 14 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2492, channel: 14 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2494, channel: 14 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN2_4G.ID, frequency: 2496, channel: 14 },
    ],
  },
  [FREQUENCY_BAND.FREQUENCY_WLAN5G.ID]: {
    first: 4920,
    last: 5740,
    list: [
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 4920, channel: 184, note: '1' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 4940, channel: 188, note: '1' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 4960, channel: 192, note: '1' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 4980, channel: 196, note: '1' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5180, channel: 36, note: '2' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5200, channel: 40, note: '2' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5220, channel: 44, note: '2' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5240, channel: 48, note: '2' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5260, channel: 52, note: '3' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5280, channel: 56, note: '3' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5300, channel: 60, note: '3' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5320, channel: 64, note: '3' },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5500, channel: 100 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5520, channel: 104 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5540, channel: 108 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5560, channel: 112 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5580, channel: 116 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5600, channel: 120 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5620, channel: 124 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5640, channel: 128 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5660, channel: 132 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5680, channel: 136 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5700, channel: 140 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_WLAN5G.ID, frequency: 5720, channel: 144 },
    ],
  },
  [FREQUENCY_BAND.FREQUENCY_L5G.ID]: {
    first: 4600,
    last: 4900,
    list: [
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_L5G.ID, frequency: 4625 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_L5G.ID, frequency: 4675 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_L5G.ID, frequency: 4725 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_L5G.ID, frequency: 4775 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_L5G.ID, frequency: 4825 },
      { frequencyBand: FREQUENCY_BAND.FREQUENCY_L5G.ID, frequency: 4875 },
    ],
  },
};

/**
 * @description WLAN2.4GHz 推奨 CH 表示用のグループデータの定数
 * @constant
 */
export const CHANNEL_GROUP_2_4G: FrequencyChannelType[] = [
  { frequency: 2412, channel: 1, min: 2412, max: 2422 },
  { frequency: 2417, channel: 2, min: 2412, max: 2427 },
  { frequency: 2422, channel: 3, min: 2412, max: 2432 },
  { frequency: 2427, channel: 4, min: 2417, max: 2437 },
  { frequency: 2432, channel: 5, min: 2422, max: 2442 },
  { frequency: 2437, channel: 6, min: 2427, max: 2447 },
  { frequency: 2442, channel: 7, min: 2432, max: 2452 },
  { frequency: 2447, channel: 8, min: 2437, max: 2457 },
  { frequency: 2452, channel: 9, min: 2442, max: 2462 },
  { frequency: 2457, channel: 10, min: 2447, max: 2467 },
  { frequency: 2462, channel: 11, min: 2452, max: 2472 },
  { frequency: 2467, channel: 12, min: 2457, max: 2472 },
  { frequency: 2472, channel: 13, min: 2462, max: 2472 },
  { frequency: 2484, channel: 14, min: 2474, max: 2496 },
];

/**
 * @description WLAN2.4GHz 推奨 CH 表示用の詳細データの定数
 * @constant
 */
export const CHANNEL_GROUP_DETAIL_2_4G: FrequencyChannelType[] = [
  { frequency: 2412, channel: 1 },
  { frequency: 2417, channel: 2 },
  { frequency: 2422, channel: 3 },
  { frequency: 2427, channel: 4 },
  { frequency: 2432, channel: 5 },
  { frequency: 2437, channel: 6 },
  { frequency: 2442, channel: 7 },
  { frequency: 2447, channel: 8 },
  { frequency: 2452, channel: 9 },
  { frequency: 2457, channel: 10 },
  { frequency: 2462, channel: 11 },
  { frequency: 2467, channel: 12 },
  { frequency: 2472, channel: 13 },
  { frequency: 2473, channel: 14, min: 2472, max: 2474 },
  { frequency: 2474, channel: 14 },
  { frequency: 2475, channel: 14, min: 2474, max: 2476 },
  { frequency: 2476, channel: 14 },
  { frequency: 2477, channel: 14, min: 2476, max: 2478 },
  { frequency: 2478, channel: 14 },
  { frequency: 2479, channel: 14, min: 2478, max: 2480 },
  { frequency: 2480, channel: 14 },
  { frequency: 2481, channel: 14, min: 2480, max: 2482 },
  { frequency: 2482, channel: 14 },
  { frequency: 2483, channel: 14, min: 2482, max: 2484 },
  { frequency: 2484, channel: 14 },
  { frequency: 2485, channel: 14, min: 2484, max: 2486 },
  { frequency: 2486, channel: 14 },
  { frequency: 2487, channel: 14, min: 2486, max: 2488 },
  { frequency: 2488, channel: 14 },
  { frequency: 2489, channel: 14, min: 2488, max: 2490 },
  { frequency: 2490, channel: 14 },
  { frequency: 2491, channel: 14, min: 2490, max: 2492 },
  { frequency: 2492, channel: 14 },
  { frequency: 2493, channel: 14, min: 2492, max: 2494 },
  { frequency: 2494, channel: 14 },
  { frequency: 2495, channel: 14, min: 2494, max: 2496 },
  { frequency: 2496, channel: 14 },
];

export const FREQ_NOTE: FreqNoteType = {
  ['1']: { label: '①', text: '①：総務省への登録（場合によっては免許）が必要なチャンネルです　' },
  ['2']: { label: '②', text: '②：屋外で使用するには、認証を得たAPが必要なチャンネルです　' },
  ['3']: { label: '③', text: '③：屋外で使用できないチャンネルです' },
};
