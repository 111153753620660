import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Box, styled, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Draggable, { DraggableBounds } from 'react-draggable';
import { SelectedDataSourceContext, SelectedFrequencyBandContext, SettingContext } from 'radioMonitoringPage';
import { MapPinType, PointType, DirectionType, MapObjType, PIN_TYPE, PinData } from 'domain/types/map';
import { formatMapPinWindowLabel } from 'utils/format';
import { checkPinBoundary } from 'utils/validate';
import { DATA_SOURCE } from 'domain/types/common/consts';
import PinIcon from '../Map/PinIcon';
import { setPropertyForGroupMapPin, setPropertyForMapPin } from 'utils/transform';
import { getLatLngOrigin, getPinPosition, getSelectedMap } from 'utils/extract';
import { convertActualPosition } from 'utils/transform';
import { RwmMapAreaContext, RwmMapTabContext } from '../RwmMapContext';
import { FrequencyType } from 'domain/types/frequency';
import { DataSourceType } from 'domain/types/setting';
import { LayoutContext } from 'components/SplitLayout/LayoutContext';
import { useMaps } from 'MapsContext';
import { RwmContext } from 'RwmContext';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFA',
    boxShadow: theme.shadows[1],
    fontSize: 8,
    color: '#000',
  },
}));

const getInitialStates = (
  mapsData: MapObjType[],
  pinId: string,
  pinData: PinData[],
  selectedMapId: string,
  selectedFrequencies: FrequencyType[],
  selectedDataSource: DataSourceType,
  selectedFrequencyBand: string,
  ne: google.maps.LatLng,
  sw: google.maps.LatLng,
  mapWidth: number,
  mapHeight: number,
  latPerPixel: number,
  lngPerPixel: number,
  bounds: DraggableBounds,
  isEnableGps: boolean,
  pinSize: number
) => {
  const selectedMap = getSelectedMap(mapsData, selectedMapId);
  const basePin = selectedMap.pins.find((pin) => pin.pinId === pinId);
  let mapPin: MapPinType | undefined;
  if (basePin) {
    if (basePin.pinType === PIN_TYPE.GroupPin) {
      mapPin = setPropertyForGroupMapPin(
        mapsData,
        basePin,
        pinData,
        selectedFrequencies,
        selectedDataSource,
        selectedMapId
      );
    } else {
      mapPin = setPropertyForMapPin(basePin, pinData, selectedFrequencies, selectedDataSource, selectedMapId);
    }

    const { hasGpsInfo } = mapPin;
    let { lat, lng } = mapPin;

    if (hasGpsInfo && pinData.length > 0) {
      const index = pinData.findIndex((data) => data.unitId === pinId);
      if (
        index !== -1 &&
        pinData[index].lat !== 0 &&
        pinData[index].lng !== 0 &&
        pinData[index].hasGpsInfo &&
        isEnableGps
      ) {
        const pin = pinData[index];
        lat = pin.lat;
        lng = pin.lng;
        mapPin.lat = pin.lat;
        mapPin.lng = pin.lng;
      }
    }

    const position = getPinPosition(
      lat,
      lng,
      latPerPixel,
      lngPerPixel,
      ne as google.maps.LatLng,
      sw as google.maps.LatLng,
      bounds,
      pinSize
    );

    if (Number.isNaN(position.x)) position.x = mapWidth / 2;
    if (Number.isNaN(position.y)) position.y = mapHeight / 2;

    const [idLabel, frequencyLabel] = formatMapPinWindowLabel(mapPin, selectedDataSource, selectedFrequencyBand);
    const value = selectedDataSource === DATA_SOURCE.NOISE ? mapPin.noise : mapPin.ratio;
    const channel = mapPin.channel;
    const pinType = mapPin.pinType;

    return {
      selectedMap,
      mapPin,
      position,
      idLabel,
      frequencyLabel,
      value,
      channel,
      pinType,
    };
  }
};

interface Props {
  map: google.maps.Map | null;
  pinId: string;
  index: number;
}

const EditPin = ({ map, pinId }: Props) => {
  const { mapWidth, mapHeight, tmpCenter, tmpZoom, bounds } = useContext(RwmMapAreaContext);
  const { selectedDataSource } = useContext(SelectedDataSourceContext);
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { setting } = useContext(SettingContext);
  const { pinData } = useContext(RwmMapTabContext);
  const { latPerPixel, lngPerPixel, ne, sw } = useContext(RwmMapAreaContext);
  const { selectedFrequencies } = useContext(RwmContext);
  const { selectedMapId, isEnableGps } = useContext(LayoutContext);
  const { mapsData } = useMaps();
  console.log(mapWidth);
  const memoizedInitialStates = useCallback(
    () =>
      getInitialStates(
        mapsData,
        pinId,
        pinData,
        selectedMapId,
        selectedFrequencies,
        selectedDataSource as DataSourceType,
        selectedFrequencyBand,
        ne as google.maps.LatLng,
        sw as google.maps.LatLng,
        mapWidth as number,
        mapHeight as number,
        latPerPixel,
        lngPerPixel,
        bounds,
        isEnableGps,
        setting.pinSize
      ),
    [
      mapsData,
      pinId,
      pinData,
      selectedMapId,
      selectedFrequencies,
      selectedDataSource,
      selectedFrequencyBand,
      ne,
      sw,
      mapWidth,
      mapHeight,
      latPerPixel,
      lngPerPixel,
      bounds,
      isEnableGps,
      setting.pinSize,
    ]
  );
  const [mapPin, setMapPin] = useState<MapPinType | undefined>(undefined);
  const [idLabel, setIdLabel] = useState<string | undefined>(undefined);
  const [frequencyLabel, setFrequencyLabel] = useState<string | undefined>(undefined);
  const [position, setPosition] = useState<PointType | undefined>(undefined);
  const [value, setValue] = useState<number | undefined>(undefined);
  const [channel, setChannel] = useState<number | undefined>(undefined);
  const [pinType, setPinType] = useState<number | undefined>(undefined);

  useEffect(() => {
    const states = memoizedInitialStates();
    if (states !== undefined) {
      setMapPin(states.mapPin);
      setPosition(states.position);
      setIdLabel(states.idLabel);
      setFrequencyLabel(states.frequencyLabel);
      setValue(states.value);
      setChannel(states.channel);
      setPinType(states.pinType);
    }

    return () => {
      setMapPin(undefined);
      setPosition(undefined);
      setIdLabel(undefined);
      setFrequencyLabel(undefined);
      setValue(undefined);
      setChannel(undefined);
      setPinType(undefined);
    };
  }, [memoizedInitialStates]);

  const getInitialPosition = useCallback(() => {
    if (map && mapPin) {
      const overlayView = new google.maps.OverlayView();
      overlayView.onAdd = function () {
        const projection = this.getProjection();

        const latLng = new google.maps.LatLng(mapPin.lat, mapPin.lng);

        // NOTE: マップの左上基準に合わせるために、マップのオフセットを考慮
        const bounds = map.getBounds();
        if (bounds) {
          // NOTE: マップの左上のピクセル値を取得 (マップの中央からのピクセル値)
          const origin = projection.fromLatLngToDivPixel(getLatLngOrigin(bounds.getNorthEast(), bounds.getSouthWest()));
          // NOTE: ピンの緯度経度からピクセル値を取得 (マップの中央からのピクセル値)
          const pin = projection.fromLatLngToDivPixel(latLng);

          // NOTE: ピンの中央位置を計算
          const pinPosition: PointType = convertActualPosition(
            {
              x: pin.x - origin.x,
              y: pin.y - origin.y,
            },
            setting.pinSize
          );

          // NOTE: 元々の緯度経度が画面外にあるか判定し、画面内に収まるよう位置を補正
          let directions: DirectionType[] = [];
          if (bounds) directions = checkPinBoundary(latLng, bounds);
          directions.forEach((direction: DirectionType) => {
            if (direction === 'top') pinPosition.y = setting.pinSize;
            else if (direction === 'bottom') pinPosition.y = (mapHeight as number) - setting.pinSize * 2;
            else if (direction === 'right') pinPosition.x = (mapWidth as number) - setting.pinSize * 2;
            else pinPosition.x = setting.pinSize / 2;
          });

          setPosition(pinPosition);
        }
      };
      overlayView.draw = function () {};
      overlayView.setMap(map);
    }
  }, [map, mapHeight, mapPin, mapWidth, setting.pinSize]);

  useEffect(() => {
    if (map) {
      google.maps.event.addListenerOnce(map, 'idle', getInitialPosition);
    }

    return () => {
      if (map) {
        google.maps.event.clearListeners(map, 'idle');
      }
    };
  }, [getInitialPosition, map, mapPin?.lat, mapPin?.lng, tmpCenter, tmpZoom]);

  if (pinType === undefined) return <></>;
  return (
    <Draggable bounds={bounds} position={position} disabled>
      <LightTooltip
        title={
          <Typography sx={{ fontSize: 8, color: '#000', zIndex: 0 }}>
            {idLabel}
            <br />
            {frequencyLabel}
            {` (${channel ? channel : '-'})`}
          </Typography>
        }
        open
        placement='right'
        sx={{ pointerEvents: 'none' }}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -14],
                },
              },
            ],
            sx: {
              zIndex: 0,
            },
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'inline-flex',
            width: setting.pinSize,
            height: setting.pinSize,
            left: 0,
            top: 0,
          }}
        >
          <PinIcon pinType={pinType} value={value} iconSize={setting.pinSize} />
        </Box>
      </LightTooltip>
    </Draggable>
  );
};

export default React.memo(EditPin);
