import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Draggable from 'react-draggable';
import { DraggableBounds } from 'react-draggable';
import { BasePinType, HeatPointType, MapObjType, MapPinType, PointType, TrackData } from 'domain/types/map';
import PinIcon from '../Map/PinIcon';
import { DATA_SOURCE } from 'domain/types/common/consts';
import { SelectedDataSourceContext, SelectedFrequencyBandContext, SettingContext } from 'radioMonitoringPage';
import { formatMapPinWindowLabel } from 'utils/format';
import { getImagePinPosition, getPinPosition } from 'utils/extract';
import { setPropertyForTrackPin } from 'utils/transform';
import { RwmMapAreaContext, RwmMapTabContext } from '../RwmMapContext';
import { RwmContext } from 'RwmContext';
import { FrequencyType } from 'domain/types/frequency';
import { DataSourceType } from 'domain/types/setting';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFA',
    boxShadow: theme.shadows[1],
    fontSize: 8,
    color: '#000',
  },
}));

const getInitialStates = (
  selectedMap: MapObjType,
  basePin: BasePinType,
  trackData: TrackData,
  selectedFrequencies: FrequencyType[],
  selectedDataSource: DataSourceType,
  selectedFrequencyBand: string,
  ne: google.maps.LatLng,
  sw: google.maps.LatLng,
  latPerPixel: number,
  lngPerPixel: number,
  bounds: DraggableBounds,
  mapWidth: number,
  mapHeight: number,
  imgLat: number,
  imgLng: number,
  imgWidth: number,
  imgHeight: number,
  imgRotate: number,
  pinSize: number
) => {
  const mapPin: MapPinType = setPropertyForTrackPin(
    basePin,
    trackData,
    selectedFrequencies,
    selectedDataSource,
    selectedMap.mapId
  );

  let position = undefined;
  if (selectedMap.mapType === 'googleMap') {
    position = getPinPosition(
      mapPin.lat,
      mapPin.lng,
      latPerPixel,
      lngPerPixel,
      ne as google.maps.LatLng,
      sw as google.maps.LatLng,
      bounds,
      pinSize
    );
  } else if (selectedMap.mapType) {
    position = getImagePinPosition(
      mapPin.lat,
      mapPin.lng,
      ne as google.maps.LatLng,
      sw as google.maps.LatLng,
      mapWidth,
      mapHeight,
      imgLat,
      imgLng,
      imgWidth,
      imgHeight,
      imgRotate,
      pinSize
    );
  }
  if (position !== undefined) {
    if (Number.isNaN(position.x)) position.x = mapWidth / 2;
    if (Number.isNaN(position.y)) position.y = mapHeight / 2;
  }

  const [idLabel, frequencyLabel] = formatMapPinWindowLabel(mapPin, selectedDataSource, selectedFrequencyBand);
  const value = selectedDataSource === DATA_SOURCE.NOISE ? mapPin.noise : mapPin.ratio;
  const channel = mapPin.channel;
  const pinType = mapPin.pinType;

  return {
    position,
    idLabel,
    frequencyLabel,
    value,
    channel,
    pinType,
  };
};

interface Props {
  pinId: string;
  basePin: BasePinType;
  bounds: DraggableBounds;
  selectedMap: MapObjType;
  trackData: TrackData;
  handleSetHeatPoints: (data: HeatPointType | undefined, pinId: string) => void;
}

const TrackPin = ({ pinId, basePin, bounds, selectedMap, trackData, handleSetHeatPoints }: Props) => {
  const { selectedDataSource } = useContext(SelectedDataSourceContext);
  const { selectedFrequencyBand } = useContext(SelectedFrequencyBandContext);
  const { setting } = useContext(SettingContext);
  const { mapWidth, mapHeight, latPerPixel, lngPerPixel, ne, sw } = useContext(RwmMapAreaContext);
  const { imageWidth, imageHeight, imageRotate, imageLat, imageLng } = useContext(RwmMapTabContext);
  const { selectedFrequencies } = useContext(RwmContext);

  const memoizedInitialStates = useCallback(
    () =>
      getInitialStates(
        selectedMap,
        basePin,
        trackData,
        selectedFrequencies,
        selectedDataSource as DataSourceType,
        selectedFrequencyBand,
        ne as google.maps.LatLng,
        sw as google.maps.LatLng,
        latPerPixel,
        lngPerPixel,
        bounds,
        mapWidth as number,
        mapHeight as number,
        imageLat,
        imageLng,
        imageWidth,
        imageHeight,
        imageRotate,
        setting.pinSize
      ),
    [
      selectedMap,
      basePin,
      trackData,
      selectedFrequencies,
      selectedDataSource,
      selectedFrequencyBand,
      ne,
      sw,
      latPerPixel,
      lngPerPixel,
      bounds,
      mapWidth,
      mapHeight,
      imageLat,
      imageLng,
      imageWidth,
      imageHeight,
      imageRotate,
      setting.pinSize,
    ]
  );

  const [idLabel, setIdLabel] = useState<string | undefined>(undefined);
  const [frequencyLabel, setFrequencyLabel] = useState<string | undefined>(undefined);
  const [position, setPosition] = useState<PointType | undefined>(undefined);
  const [value, setValue] = useState<number | undefined>(undefined);
  const [channel, setChannel] = useState<number | undefined>(undefined);
  const [pinType, setPinType] = useState<number | undefined>(undefined);

  useEffect(() => {
    const states = memoizedInitialStates();
    if (states !== undefined) {
      setPosition(states.position);
      setIdLabel(states.idLabel);
      setFrequencyLabel(states.frequencyLabel);
      setValue(states.value);
      setChannel(states.channel);
      setPinType(states.pinType);
    }

    return () => {
      setPosition(undefined);
      setIdLabel(undefined);
      setFrequencyLabel(undefined);
      setValue(undefined);
      setChannel(undefined);
      setPinType(undefined);
    };
  }, [memoizedInitialStates]);

  useEffect(() => {
    if (position !== undefined && value !== undefined) {
      handleSetHeatPoints({ x: position.x, y: position.y, value, id: pinId }, pinId);
    } else handleSetHeatPoints(undefined, pinId);
  }, [handleSetHeatPoints, pinId, position, value]);

  /**
   * @description 右クリック時の処理
   */
  const handleRightClickMapPin = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
  };

  if (pinType === undefined) return <></>;
  return (
    <>
      <Draggable bounds={bounds} position={position} disabled={true}>
        <LightTooltip
          title={
            setting.isDisplayPinLabel && (
              <Typography sx={{ fontSize: 8, color: '#000', zIndex: 0 }}>
                {idLabel}
                <br />
                {frequencyLabel}
                {` (${channel ? channel : '-'})`}
              </Typography>
            )
          }
          open
          placement='right'
          sx={{ pointerEvents: 'none' }}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
              sx: {
                zIndex: 0,
              },
            },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              display: 'inline-flex',
              width: setting.pinSize,
              height: setting.pinSize,
              left: 0,
              top: 0,
            }}
            onContextMenu={handleRightClickMapPin}
          >
            <PinIcon
              pinType={pinType}
              value={value}
              iconSize={setting.pinSize}
              sx={{
                cursor: 'default',
                '&:active': {
                  cursor: 'default',
                },
              }}
            />
          </Box>
        </LightTooltip>
      </Draggable>
    </>
  );
};

export default React.memo(TrackPin);
